import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setEditDeviceData,
  setEditGroupData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AddPaymentAPI,
  GetAllPayments,
  UploadFile,
} from "../../../../../actions";

const AddPayment = (props) => {
  const [edit, setEdit] = useState("");
  const [cus, setCus] = useState("");
  const [engzId, setEngzId] = useState("");
  const [userOpt, setUserOpt] = useState("");
  const [amount, setAmount] = useState("");
  const [ref, setRef] = useState("");
  const [subType, setSubType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [engzList, setEngzList] = useState("");
  const [file, setFile] = useState("");
  const [note, setNote] = useState("");
  const [_erpProjectList, set_erpProjectList] = useState([]);
  const [project, setProject] = useState("");
  const [fileRef, setFileRef] = useState("");
  const [modeId, setModeId] = useState("");

  const [customDate, setCustomDate] = useState(0);

  const isLoading = useSelector((state) => state.user.isLoading);
  const customers = useSelector((state) => state.user.customers);
  const list = useSelector((state) => state.efig.staticList);
  const erpProjectList = useSelector((state) => state.erp.erpProjectList);
  const erpCustomersList = useSelector((state) => state.erp.erpCustomersList);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cus) {
      const obj = erpCustomersList.find(
        (o) => o.id === cus?.value?.erpCustomerId
      );

      setProject("");
      set_erpProjectList([]);
      if (obj) {
        const fiteredList = erpProjectList.filter(
          (project) => project.socid == obj?.erpId
        );
        set_erpProjectList(fiteredList);
      }
    }
  }, [cus]);

  useEffect(() => {
    const arr = [];
    customers?.forEach((val) => {
      console.log("fffff", val);
      arr.push({
        value: val,
        label: `${val?.customerFirstname} ${val?.customerLastname} ( ${val?.address} )`,
      });
    });
    setUserOpt(arr);
  }, [customers]);

  useEffect(() => {
    if (engzList?.length === 1) {
      setEngzId(engzList[0].id);
    }
  }, [engzList]);

  useEffect(() => {
    const arr = [];
    if (list?.length) {
      list.forEach((val) => {
        if (val?.userId === cus?.value?.id) {
          arr.push(val);
        }
      });
    }
    setEngzList(arr);
  }, [cus]);

  const getEngzList = () => {
    return engzList?.map((engz) => {
      return <option value={engz?.id}>{engz?.espSerial}</option>;
    });
  };

  const upload = async () => {
    if (file) {
      return await UploadFile(file, "paymentImage");
    }
  };

  // useEffect(() => {
  //   if (editGroupData) {
  //     //   alert(editGroupData);
  //     setGroupName(editGroupData?.name);
  //     setEdit("1");
  //     setGroupId(editGroupData?.id);
  //     dispatch(setEditGroupData(null));
  //   }
  // }, [editGroupData]);

  const Add = async () => {
    if (IsEmpty(cus?.value?.id)) {
      errorToast("EFIG User is required");
      return;
    }
    if (IsEmpty(amount)) {
      errorToast("Amount is required");
      return;
    }
    if (IsEmpty(subType)) {
      errorToast("Subscription Type is required");
      return;
    }
    if (IsEmpty(startDate)) {
      errorToast("Start Date is required");
      return;
    }
    if (IsEmpty(paymentDate)) {
      errorToast("Payment Date is required");
      return;
    }
    if (IsEmpty(ref)) {
      errorToast("Payment ref is required");
      return;
    }
    if (IsEmpty(engzId)) {
      errorToast("Energizer ID is required");
      return;
    }
    if (IsEmpty(fileRef)) {
      errorToast("ERP File reference is required");
      return;
    }

    if (file) {
      upload();
    }

    dispatch(setIsLoading(true));

    console.log("fffff", cus);
    // return;

    await AddPaymentAPI(dispatch, {
      userId: cus?.value?.id,
      energizerId: engzId,
      startDate,
      paymentDate,
      subscription: subType,
      amount,
      note,
      ref,
      days: customDate,
      fileRef,
      modeId,
      erpProjectId: project?.erpId,
    })
      .then(async () => {
        await GetAllPayments(dispatch);
        emptyFields();
        dispatch(setIsLoading(false));
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
  };

  const emptyFields = (emptyEdit = true) => {
    setAmount("");
    setRef("");
    setPaymentDate("");
    setStartDate("");
    setSubType("");
    setCus("");
    setEngzId("");
    setCustomDate("");
    setProject("");
    setFileRef("");
    setModeId("");
    set_erpProjectList([]);
    if (emptyEdit) {
      setEdit(null);
    }
  };

  // const update = async () => {
  //   if (IsEmpty(groupId)) {
  //     errorToast("Group Id is required");
  //     return;
  //   }
  //   if (IsEmpty(groupName)) {
  //     errorToast("Group Name is required");
  //     return;
  //   }

  //   dispatch(setIsLoading(true));

  //   await UpdateGroupAPI(dispatch, {
  //     groupId,
  //     name: groupName,
  //   })
  //     .then(async () => {
  //       setGroupName("");
  //       setGroupId("");
  //       await GetAllGroups(dispatch);
  //       dispatch(setIsLoading(false));
  //     })
  //     .catch((e) => {
  //       dispatch(setIsLoading(false));
  //     });
  // };

  return (
    <>
      <Modal
        title={edit ? "Update Payment" : "Add Payment"}
        name={"addPayment"}
        icon={"fa-regular fa-credit-card"}
      >
        <div style={{ height: "70vh" }}>
          <div className="col-12">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                onClick={() => {
                  setEdit(null);
                  emptyFields();
                }}
                title="Go To List"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#listPayments"}
              ></i>
            </a>
          </div>
          <form>
            <>
              <div className="row">
                <div className="col-lg-4">
                  <label className="col-form-label">{"EFIG User"}:</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={cus}
                    isLoading={isLoading}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    value={cus}
                    options={userOpt}
                    onChange={(e) => setCus(e || null)}
                  />
                </div>
                <div className=" col-lg-4">
                  <label className="col-form-label">{"Project"}:</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    value={project}
                    options={
                      _erpProjectList.length ? _erpProjectList : erpProjectList
                    }
                    onChange={(e) => setProject(e || null)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4">
                  <MyTextInput
                    name={"Amount"}
                    val={amount}
                    placeholder={"Amount"}
                    setVal={setAmount}
                    type={"number"}
                  />
                </div>
                <div className="col-lg-4">
                  <MyTextInput
                    name={"Reference"}
                    val={ref}
                    placeholder={"Reference"}
                    setVal={setRef}
                  />
                </div>
                <div className="col-lg-4">
                  <label className="col-form-label">
                    {"Subcription type"}:
                  </label>
                  <select
                    className="form-select form-select"
                    aria-label="Default select example"
                    value={subType}
                    onChange={(e) => setSubType(e.target.value)}
                  >
                    <option value={""}></option>
                    <option value="1">06 Months</option>
                    <option value="2">01 Year</option>
                    <option value="3">Custom</option>
                  </select>
                </div>
                {subType === "3" ? (
                  <div className="col-lg-4">
                    <MyTextInput
                      name={"Number of days"}
                      val={customDate}
                      placeholder={"Number of days"}
                      setVal={setCustomDate}
                    />
                  </div>
                ) : null}
                <div className="col-lg-4">
                  <label className="col-form-label">{"Start Date:"}</label>

                  <div style={{ width: "100%" }}>
                    <DatePicker
                      wrapperClassName="datePicker"
                      placeholderText="Start Date"
                      className=" form-control"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="col-form-label">{"Payment Date:"}</label>
                  <div>
                    <DatePicker
                      wrapperClassName="datePicker"
                      placeholderText="Payment Date"
                      className="form-control "
                      selected={paymentDate}
                      onChange={(date) => setPaymentDate(date)}
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
                {engzList?.length > 1 ? (
                  <>
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        {"Energizer Serial"}:
                      </label>
                      <select
                        className="form-select form-select"
                        aria-label="Default select example"
                        value={engzId}
                        onChange={(e) => setEngzId(e.target.value)}
                      >
                        <option value={""}></option>
                        {getEngzList()}
                      </select>
                    </div>
                  </>
                ) : (
                  <>
                    {subType === "3" ? null : <div className="col-lg-4"></div>}
                  </>
                )}
                <div className="col-lg-4">
                  <MyTextInput
                    name={"ERP File reference"}
                    val={fileRef}
                    placeholder={"File reference"}
                    setVal={setFileRef}
                  />
                </div>

                <div className="col-lg-4">
                  <label className="col-form-label">
                    {"ERP Payment Method"}:
                  </label>
                  <select
                    className="form-select form-select"
                    aria-label="Default select example"
                    value={modeId}
                    onChange={(e) => setModeId(e.target.value)}
                  >
                    <option value={""}></option>
                    <option value="2">Bank transfer</option>
                    <option value="3">Debit payment order</option>
                    <option value="4">Cash</option>
                    <option value="6">Credit Card</option>
                    <option value="7">Cheque</option>
                  </select>
                </div>
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                  <label className="col-form-label">Attachment:</label>
                  <div className="input-group mb-3">
                    <input
                      type="file"
                      className="form-control"
                      id="inputGroupFile02"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="col-form-label">Note:</label>
                  <div className="input-group mb-3">
                    <textarea
                      className="form-control"
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    ></textarea>
                    {/* <input
                      type="file"
                      className="form-control"
                      id="inputGroupFile02"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    /> */}
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4" style={{ textAlign: "center" }}>
                  {isLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      &nbsp; Loading...
                    </button>
                  ) : edit ? (
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        // update();
                      }}
                    >
                      Update Payment
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        Add();
                      }}
                    >
                      Add Payment
                    </button>
                  )}
                </div>
              </div>
              <br />
            </>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddPayment;
