/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BlockUser,
  DeleteServiceContact,
  DeleteUser,
  GetUsers,
  SearchStaff,
  UnBlockUser,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { URL } from "../../../../../config";
import {
  setActionVal,
  setConfirmText,
  setEditUserData,
  setImageDetail,
  setIsLoading,
  setPrevModal,
  setSelectedUser,
} from "../../../../../redux/slices/userSlice";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { globalDeleteBtn } from "../../../../../components/DeleteConfirm";

const ListUser = (props) => {
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [userType, setUserType] = useState("");
  const [contact, setContact] = useState("");
  const [username, setUsername] = useState("");
  const [cnic, setCnic] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [groupId, setGroupId] = useState("");

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);

  const user = useSelector((state) => state.user.user);
  const wpSearchedUsers = useSelector((state) => state.user.wpSearchedUsers);
  const groups = useSelector((state) => state.user.groups);

  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);

  useEffect(() => {
    GetUsers(dispatch);
  }, []);

  const groupList = () => {
    return groups.map((val, index) => {
      return (
        <option key={val.id} value={val.id}>
          {val.name}
        </option>
      );
    });
  };

  const searchUsers = async () => {
    dispatch(setIsLoading(true));
    await SearchStaff(dispatch, {
      name,
      contact,
      userType,
      email,
      groupId,
    });

    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "user") {
      deleteStaffAction(actionVal.id);
      dispatch(setActionVal(null));
    }
    if (actionVal?.action === "1" && actionVal?.type === "service") {
      deleteServiceContactAction(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  const updateUserState = async (cus) => {
    if (cus.blocked) {
      await UnBlockUser({ userId: cus.id })
        .then((res) => {
          succesToast("User Unblocked.");
          searchUsers();
        })
        .catch((e) => {
          errorToast(e?.response?.data?.err || "Error");
        });
    } else {
      await BlockUser({ userId: cus.id })
        .then((res) => {
          succesToast("Customer blocked.");
          searchUsers();
        })
        .catch((e) => {
          errorToast(e?.response?.data?.err || "Error");
        });
    }
  };

  const deleteStaff = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this User?",
        type: "user",
        id,
      })
    );
    globalDeleteBtn.click();
  };

  const deleteStaffAction = async (id) => {
    await DeleteUser(dispatch, { userId: id })
      .then()
      .catch((e) => {
        console.log("e", e);
      });
    await searchUsers();
  };

  const deleteServiceContact = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this Service Contact?",
        type: "service",
        id,
      })
    );
    globalDeleteBtn.click();
  };

  const deleteServiceContactAction = async (id) => {
    await DeleteServiceContact(dispatch, { userId: id })
      .then()
      .catch((e) => {
        console.log("e", e);
      });
    await searchUsers();
  };

  const getList = () => {
    const allUser = wpSearchedUsers ? [...wpSearchedUsers] : [];
    let count = 0;
    return allUser?.map((val, index) => {
      count++;
      return (
        <Fragment key={index}>
          <tr>
            <th scope="row">{count}</th>
            <td
              className={`clickable_${val?.id}`}
            >{`${val?.firstname} ${val?.lastname}`}</td>
            <td>{`${val.contact}`}</td>
            <td>{`${val.email || ""}`}</td>
            <td>
              {val.roleId === 2
                ? "Admin"
                : val.roleId === 6
                ? "Supervisor"
                : val.roleId === 7
                ? "Operator"
                : val.roleId === 8
                ? "Technician"
                : ""}
            </td>
            <td>{val.efigGroup?.name || ""}</td>

            <td style={{ display: "flex", flexDirection: "row" }}>
              <a
                href="#"
                className={`nav-link ${
                  user.roleId === 1 || user?.accessControl?.accessControl
                    ? ""
                    : "disabled"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: "black",
                    opacity:
                      user.roleId === 1 || user?.accessControl?.accessControl
                        ? 1
                        : 0.4,
                  }}
                  className="fa fa-solid fa-lock"
                  onClick={() => {
                    dispatch(setSelectedUser(val));
                  }}
                  title="Access Control"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target="#userAccessControl"
                ></i>
              </a>

              <a
                className={`nav-link ${
                  user.roleId === 1 || user?.accessControl?.addUser
                    ? ""
                    : "disabled"
                }`}
                href="#"
              >
                <i
                  style={{
                    fontSize: "20px",
                    marginLeft: "10px",
                    color: "blue",
                    opacity:
                      user.roleId === 1 || user?.accessControl?.addUser
                        ? 1
                        : 0.4,
                  }}
                  className="fa fa-light fa-pencil"
                  onClick={() => {
                    dispatch(setEditUserData(val));
                  }}
                  title="Edit"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target="#addUser"
                ></i>
                <i
                  style={{
                    fontSize: "20px",
                    color: "red",
                    marginLeft: "10px",
                    opacity:
                      user.roleId === 1 || user?.accessControl?.addUser
                        ? 1
                        : 0.4,
                  }}
                  className="fa fa-light fa-trash"
                  onClick={() => {
                    if (val.roleId === 21) {
                      deleteServiceContact(val.id);
                    } else {
                      deleteStaff(val.id);
                    }
                  }}
                  title="Delete"
                ></i>
                <i
                  style={{
                    fontSize: "20px",
                    color: val.blocked ? "green" : "red",
                    marginLeft: "10px",
                    opacity:
                      user.roleId === 1 || user?.accessControl?.addUser
                        ? 1
                        : 0.5,
                  }}
                  className="fa fa-light fa-ban"
                  onClick={() => updateUserState(val)}
                  title={val.blocked ? "Un-Block" : "Block"}
                ></i>
              </a>
            </td>
          </tr>
        </Fragment>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Staff List"}
        name={"listUser"}
        icon={"fa fa-users-viewfinder"}
      >
        <div className="row">
          <div className="col-9">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add User"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addUser"}
              ></i>
            </a>
          </div>

          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>

                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      placeholder={"Name"}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label className="col-form-label">Name</label>
                  </th>
                  {/* <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={email}
                      placeholder={"Email"}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label className="col-form-label">Email</label>
                  </th> */}
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={contact}
                      placeholder={"Cell Phone #"}
                      onChange={(e) => setContact(e.target.value)}
                    />
                    <label className="col-form-label">Cell Phone #</label>
                  </th>

                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      value={email}
                      placeholder={"Email"}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label className="col-form-label"> Email</label>
                  </th>
                  <th>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={userType}
                      onChange={(e) => setUserType(e.target.value)}
                    >
                      <option value={""}></option>
                      {user.roleId === 1 ? (
                        <option value="2"> Admin</option>
                      ) : null}
                      <option value="6"> Supervisor</option>
                      <option value="7"> Operator</option>
                      <option value="8"> Technician</option>
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">User Type</label>
                  </th>
                  <th style={{ width: "120px" }}>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={groupId}
                      onChange={(e) => setGroupId(e.target.value)}
                    >
                      <option value=""></option>
                      {groupList()}
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Group</label>
                  </th>

                  <th style={{ width: "80px" }}>
                    {isLoading ? (
                      <button
                        style={{
                          width: "120px",
                          textAlign: "center",
                        }}
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        style={{
                          width: "120px",
                        }}
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          searchUsers();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">Action</label>
                  </th>
                </tr>
              </thead>

              <tbody>{getList()}</tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListUser;
